import { reactive } from "vue";
import { io } from "socket.io-client";
import store from './store'

// "undefined" means the URL will be computed from the `window.location` object
//const URL = process.env.NODE_ENV === "production" ? undefined : "http://localhost:3000";
const URL = "sahpadmin.api.states-grand.com";

export const app = 'States Toolbox V22.08.2024'


let userName = "";
const element = document.querySelector("#app");

const newUserConnected = (user) => {
    userName = user || `${Math.floor(Math.random() * 1000000)}`;

    const obj = {
        org: element.hasAttribute('data-org') && element.dataset.org.length > 0 ? element.dataset.org : '',
        userName,
        theme: localStorage.getItem('toolboxTheme') || 'clss',
        mode: localStorage.getItem('toolboxMode') || '0',
        hidden: localStorage.getItem('toolboxSharing') || 'false',
        app
    }

    socket.emit("new user", Object.assign({}, obj, {app}));

    return obj
  };


const attr =  element.hasAttribute('data-us') && element.dataset.us.length > 0 ? element.dataset.us : false;

export const state = reactive({
  connected: false,
  user: '',
  count: 0,
});

export const socket = io(URL);

socket.on("connect", () => {
  state.connected = true;
  state.user = newUserConnected(attr)
});

socket.on("disconnect", () => {
  state.connected = false;
});

socket.on("new user", function (data) {

    state.count = data.length

    store.commit('setToolboxUsers', data)
});

socket.on("changedToolboxMode", function (data) {

  store.commit('setToolboxUserByName', data)
});

socket.on("changedToolboxTheme", function (data) {

  store.commit('setToolboxUserByName', data)
});

socket.on("changedToolboxSharing", function (data) {

  store.commit('setToolboxUserByName', data)
});


socket.on("user disconnected", function (data) {
    store.commit('setToolboxUsers', data)
});