import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Bußgeldkatalog from '../views/Bußgeldkatalog.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Bußgeldkatalog
  },  
  // {
  //   path: '/bussgeldkatalog',
  //   name: 'Bußgeldkatalog',
  //   component: Bußgeldkatalog
  // },
  {
    path: '/generate',
    name: 'Generator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Generator.vue')
  }, 
  {
    path: '/userSettings',
    name: 'User Settings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UserSettings.vue')
  },  
  {
  path: '/meldeamt',
  name: 'Meldeamt',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/Meldeamt.vue')
},
{
  path: '/internal',
  name: 'InternalMenu',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/InternalMenu.vue')
},
{
  path: '/blitzerfoto',
  name: 'Blitzerfoto',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/Blitzerfoto.vue')
}  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
