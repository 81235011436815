
import { Options, Vue } from 'vue-class-component';
import axios from 'axios'
import { socket, state, app } from "@/socket";

@Options({
  data() {
    return {
      serverVersion: '',
      testVersion: ''
    }
  },
  computed: {
    frontendVersion() {
      return this.$store.getters.getFrontendVersion
    },
    theme() {
      return this.$store.getters.getToolboxTheme
    },    
    onlineMembers() {
      return state.count
    },
    userName() {
      return `${ ( (state.user.hasOwnProperty('org') && state.user['org'] ) ? '[' + state.user['org'] + '] ' : '' )}${state.user['userName']}`
    },
    org() {
      return state.user.hasOwnProperty('org') && state.user['org']
    },
    appName() {
      return app
    }
  },
  created() {
    this.$store.dispatch('getLawsfetch')
    axios.get(`${this.$store.getters.getServer}api/frontendVersion`)
      .then(({data}) => {
        this.serverVersion = data.frontendVersion
        this.testVersion = data.testVersion

        if(data.frontendVersion != this.frontendVersion && data.testVersion != this.frontendVersion) {
          localStorage.clear()
        }

        socket.connect();
        this.$store.dispatch('initToolboxSettings')
        return data
      })
      .catch(x => this.serverVersion = 'Offline')
  }
})
export default class App extends Vue {}
