
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'Bußgeldkatalog',
  
  data() {
    return {
      tmpInput: '',
    };
  },

  computed: {
    ...mapGetters({
      laws: 'getLaws',
    }),
    
    getLaws(): any {
      const obj = JSON.parse(JSON.stringify(this.laws));
      const input = this.tmpInput.length > 0 ? this.tmpInput.toLowerCase() : false;

      if (!input) return obj;

      return obj.filter((x: any) => {
        const topic = x.topic.toLowerCase();
        const topicShortener = topic.indexOf(input) > -1;

        const laws = x.paragraphs.filter((y: any) => {
          const law = y.law.toLowerCase();
          const paragraph = y.paragraph.toLowerCase();

          return topicShortener || (law.indexOf(input) > -1 || paragraph.indexOf(input) > -1);
        });

        x.paragraphs = laws;

        return laws.length > 0 ? x : false;
      }).filter(Boolean); // Remove false values
    },
  },
});
